import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';

// Utils
import { cn } from 'utils';

// Styles
import styles from './KeyFigure.module.css';

function KeyFigure({
  title, subtitle, media, link, theme, className,
}) {
  const TagImg = link ? Link : 'div';
  return (
    <div className={cn([styles.wrapper, styles[theme]])}>
      <div className={cn([styles.container, className])}>
        {title && !media?.url ? <h2 className={styles.title}>{title}</h2> : null}
        {media?.url ? (
          <TagImg href={link || undefined} name={title} className={styles.containerImg}>
            <Image
              fill
              src={media.url}
              alt={media?.description}
              sizes="(max-width: 1024px) 75vw,
                                         33vw"
            />
          </TagImg>
        ) : null}
        {subtitle ? (
          <span className={styles.subtitle}>{subtitle}</span>
        ) : null}
      </div>
    </div>
  );
}

KeyFigure.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  media: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  link: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '', 'transparent']),
  className: PropTypes.string,
};

KeyFigure.defaultProps = {
  subtitle: '',
  title: '',
  media: {
    url: '',
    description: '',
  },
  link: '',
  theme: 'light',
  className: '',
};

export default KeyFigure;
