import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Styles
import styles from './KeyFiguresBlock.module.css';

// Components
import KeyFigure from '../KeyFigure/KeyFigure';

function KeyFiguresBlock({
  items, theme, style,
}) {
  return (
    <div className={cn([styles.wrapper, styles[theme]])} style={style}>
      <div className={styles.container}>
        {items?.length ? items.map(({ data }) => (
          <KeyFigure
            title={data?.title}
            subtitle={data?.subtitle}
            media={data?.media}
            link={data?.link}
            theme={theme}
            key={data?.title}
          />
        )) : null}
      </div>
    </div>
  );
}

KeyFiguresBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      media: PropTypes.oneOfType([
        PropTypes.shape({
          url: PropTypes.string,
          description: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      link: PropTypes.string,
    }),
  })),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '', 'transparent']),
  style: PropTypes.shape({}),
};

KeyFiguresBlock.defaultProps = {
  items: [],
  theme: 'light',
  style: null,
};

export default KeyFiguresBlock;
